import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../components/header';
import Text from '../components/text';
import aboutText from '../assests/texts/about.js';
// import partnersText from '../assests/texts/partners';
import Player from '../components/serie/player';
// import Button from '../components/button';
// import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  topContainer: {
    direction: 'rtl',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sectionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '45px 0px',
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      margin: '0px',
      marginBottom: '50px',
    },
  },
  bannersSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: '45px 0px',
    width: '75%',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0px',
    },
  },
  imageBox: {
    width: '400px',
    height: '230px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      height: '180px',
      marginTop: '10px',
    },
  },
  imageBoxBig: {
    width: '100%',
    height: '285px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      height: '180px',
    },
  },
  videoCon: {
    margin: '0px 10px',
  },
  title: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  textBox: {
    display: 'flex',
    width: '500px',
    flexDirection: 'column',
    margin: '0px 5px',
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      margin: '0px',
      marginTop: '30px',
    },
  },
  banner: {
    display: 'flex',
    width: '500px',
    flexDirection: 'column',
    margin: '0px 5px',
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      margin: '0px',
      marginTop: "15px"
    },
  },
  paragraph: {
    display: 'flex',
  },
  btnContainer: {
    marginTop: '10px',
    display: 'flex',
  },
  btnLink: {
    marginLeft: '10px',
    marginTop: '10px',
  },
  desTitle: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileTitle: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  bannersContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '20px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
      marginTop: '0px',
    },
  },
}));

const About = () => {
  const classes = useStyles();
  const mobile = window.innerWidth < 400;
  const customWidth = mobile ? null : '500px';
  const customHeight = mobile ? null : '280px';
  return (
    <div className={classes.topContainer}>
      <Header
        imageUrl={'https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/aboutDes.webp'}
      >
        <Text color="white" size="xxl" fontWeight={600} textShadow>
          אודות
        </Text>
      </Header>
      <div className={classes.sectionContainer}>
        <div className={classes.textBox}>
          <div className={classes.title}>
            <Text color="black" size="lg" fontWeight={600} textAlign="start">
              {aboutText.yazhiru.title}
            </Text>
          </div>
          <div className={classes.paragraph}>
            <Text color="black" size="sm" textAlign="start">
              {aboutText.yazhiru.p1}
            </Text>
          </div>
          <div className={classes.paragraph}>
            <Text color="black" size="sm" textAlign="start">
              {aboutText.yazhiru.p2}
            </Text>
          </div>
          <div className={classes.paragraph}>
            <Text color="black" size="sm" textAlign="start">
              {aboutText.yazhiru.p3}
            </Text>
          </div>
          <div className={classes.paragraph}>
            <Text color="black" size="sm" textAlign="start">
              {aboutText.yazhiru.p4}
            </Text>
          </div>
        </div>
        <div
          className={classes.imageBox}
          style={{ backgroundImage: `url(${aboutText.yazhiru.backgroundImage})` }}
        ></div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={`${classes.title} ${classes.mobileTitle}`}>
          <Text color="black" size="lg" fontWeight={600} textAlign="start">
            {aboutText.yeshiva.title}
          </Text>
        </div>
        <div className={classes.videoCon}>
          <Player
            url={'https://youtu.be/Nx_8RDIVhTQ'}
            text={null}
            customWidth={customWidth}
            customHeight={customHeight}
            light="https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/about/yeshiva.jpg"
          />
        </div>
        <div className={classes.textBox}>
          <div className={`${classes.title} ${classes.desTitle}`}>
            <Text color="black" size="lg" fontWeight={600} textAlign="start">
              {aboutText.yeshiva.title}
            </Text>
          </div>
          <div className={classes.paragraph}>
            <Text color="black" size="sm" textAlign="start">
              {aboutText.yeshiva.p1}
            </Text>
          </div>
          <div className={classes.paragraph}>
            <Text color="black" size="sm" textAlign="start">
              {aboutText.yeshiva.p2}
            </Text>
          </div>
          <div className={classes.paragraph}>
            <Text color="black" size="sm" fontWeight={600} textAlign="start">
              {aboutText.yeshiva.details}
            </Text>
          </div>
          {/* <div className={classes.btnContainer}>
            <Link className={classes.btnLink} to={{ pathname: aboutText.yeshiva.link }} target="_blank">
              <Button btnColor="yellow" onClick={() => null}>
                <Text size="sm">לאתר הישיבה</Text>
              </Button>
            </Link>
            <Link className={classes.btnLink} to={{ pathname: partnersText.link }} target="_blank">
              <Button btnColor="yellow" onClick={() => null}>
                <Text size="sm">לתרומה </Text>
              </Button>
            </Link>
          </div> */}
        </div>
      </div>
      {/* <div className={classes.bannersSection}>
        <div className={classes.title}>
          <Text color="black" size="lg" fontWeight={600} textAlign="start">
            {aboutText.sadnaot.title}
          </Text>
        </div>
        <div className={classes.bannersContainer}>
          <div className={classes.banner}>
            <Text color="black" size="md" fontWeight={600} textAlign="start">
              {aboutText.sadnaot.veitbonanta.title}
            </Text>
            <Link className={classes.imageLink} to={{ pathname: aboutText.sadnaot.veitbonanta.link }} target="_blank">
              <div
                className={classes.imageBoxBig}
                style={{ backgroundImage: `url(${aboutText.sadnaot.veitbonanta.backgroundImage})` }}
              ></div>
            </Link>
          </div>
          <div className={classes.banner}>
            <Text color="black" size="md" fontWeight={600} textAlign="start">
              {aboutText.sadnaot.dmamaDaka.title}
            </Text>
            <Link className={classes.imageLink} to={{ pathname: aboutText.sadnaot.dmamaDaka.link }} target="_blank">
              <div
                className={classes.imageBoxBig}
                style={{ backgroundImage: `url(${aboutText.sadnaot.dmamaDaka.backgroundImage})` }}
              ></div>
            </Link>
          </div>
        </div>
      </div> */}
      {/* <div className={classes.sectionContainer}>
        <div className={classes.textBox}>
          <div className={classes.title}>
            <Text color="black" size="lg" fontWeight={600} textAlign="start">
              {aboutText.tfilot.title}
            </Text>
          </div>
          <div
            className={classes.imageBoxBig}
            style={{ backgroundImage: `url(${aboutText.tfilot.backgroundImage})` }}
          ></div>
          <div className={classes.paragraph}>
            <Text color="black" size="sm" textAlign="start">
              {aboutText.tfilot.text}
            </Text>
          </div>
          <Link className={classes.btnLink} to={{ pathname: aboutText.tfilot.link }} target="_blank">
            <Button btnColor="yellow" onClick={() => null}>
              <Text size="sm">להשארת שמות </Text>
            </Button>
          </Link>
        </div>
        <div className={classes.textBox}>
          <div className={classes.title}>
            <Text color="black" size="lg" fontWeight={600} textAlign="start">
              {aboutText.shabatot.title}
            </Text>
          </div>
          <div
            className={classes.imageBoxBig}
            style={{ backgroundImage: `url(${aboutText.shabatot.backgroundImage})` }}
          ></div>
          <div className={classes.paragraph}>
            <Text color="black" size="sm" textAlign="start">
              {aboutText.shabatot.text}
            </Text>
          </div>
          <Link className={classes.btnLink} to={{ pathname: aboutText.shabatot.link }} target="_blank">
            <Button btnColor="yellow" onClick={() => null}>
              <Text size="sm"> ליצירת קשר </Text>
            </Button>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default About;
