import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#007cc2', blue: '#007cc2', gray: '#6c6c6c', yellow: '#fff500', white: '#fff', black: '#000' ,lightGray:"#f0f0f0"},
    textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
    fontsDes: {
      xxs: '14px',
      xs: '16px',
      sm: '17px',
      md: '20px',
      lg: '25px',
      xl: '35px',
      xxl: '45px',
    },
    fontsMobile: {
      xxs: '14px',
      xs: '15px',
      sm: '18px',
      md: '20px',
      lg: '24px',
      xl: '26px',
      xxl: '30px',
    },
  },
});
export default theme;
