import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    left: '0px',
    bottom: '0px',
    backgroundColor: 'rgba(0,0,0,0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  dialog: {
    backgroundColor: '#fff',
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  iconButton: {
    marginLeft: 'auto',
    position: 'absolute',
    right: '5px',
    top: '5px',
  },
}));

const Dialog = ({ open, onClose, children }) => {
  const classes = useStyles();

  if (!open) {
    return null;
  }
  return (
    <div className={classes.cover} onClick={onClose}>
      <div className={classes.dialog} onClick={(event) => event.stopPropagation()}>
        <IconButton onClick={onClose} className={classes.iconButton}>
          <Close />
        </IconButton>
        {children}
      </div>
    </div>
  );
};

export default Dialog;
