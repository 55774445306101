import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Cards from '../components/cards';
import Header from '../components/header';
import Button from '../components/button';
import Text from '../components/text';
import SearchLesson from '../components/searchLesson';
import SearchIcon from '@mui/icons-material/Search';
import MainNav from '../components/navs/mainNav';
import { useParams } from 'react-router-dom';
import { MainContext } from '../context';
import { getMapCategory, getSeriesByCategory, getCategoryFieldById } from '../utils/categoryFilter';

const useStyles = makeStyles((theme) => ({
  topContainer: {},
  cardsCon: {
    width: '80%',
    maxWidth: '1140px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  navCon: {
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      margin: '20px',
      marginTop: '20px',
      marginBottom: '65px',
    },
  },
  main: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    direction: 'rtl',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0px',
      alignItems: 'center',
    },
  },
}));

const Series = () => {
  const classes = useStyles();
  const [searchMode, setSearchMode] = useState(false);
  const { data } = useContext(MainContext);
  const { categoryId } = useParams();
  const { categories, series } = data;
  const allCategories = getMapCategory(categories);
  const partCategories = getMapCategory(categories, [0, 1]);
  console.log(allCategories,"allCategories")
  const backImageUrl = getCategoryFieldById(allCategories, categoryId, 'backImageUrl');
  const categoryName = getCategoryFieldById(allCategories, categoryId, 'name');
  const cards = parseInt(categoryId) === 0 ? partCategories : getSeriesByCategory(series, categoryName);
  const categoriesWithUrl = allCategories.map((item) => ({ ...item, navUrl: `/series/${item.number}` }));
  return (
    <div className={classes.topContainer}>
      <Header imageUrl={backImageUrl}>
        {!searchMode && (
          <Button backgroundImage width="150px" borderColor="white" onClick={() => setSearchMode(true)}>
            <SearchIcon />
            <Text color={'white'} size="xs">
              חפש שיעורים
            </Text>
          </Button>
        )}
      </Header>
      <div className={classes.main}>
        {searchMode && <SearchLesson series={series} categories={allCategories} onExit={() => setSearchMode(false)} />}
        <div className={classes.navCon}>
          <MainNav title={categoryName} items={categoriesWithUrl} selectedItemNumber={categoryId} />
        </div>
        <div className={classes.cardsCon}>
          <Cards data={cards} categoryId={categoryId} />
        </div>
      </div>
    </div>
  );
};

export default Series;
