import { sortBy, get, first } from 'lodash/fp';

const getSeriesByCategory = (series, categoryName) => {
  return sortBy(
    'order',
    series.filter((item) => item.categories.includes(categoryName))
  );
};

const getCategoryFieldById = (categories, categoryId, field) => {
  const filteredCategories = categories.filter((item) => {
    return item.number === categoryId;
  });
  return get(field, first(filteredCategories));
};

const getMapCategory = (categories, filter) => {
  const keys = Object.keys(categories);
  const allCatgories = keys.map((key) => {
    const name = categories[key].name;
    const imageUrl = categories[key].imageUrl;
    const backImageUrl = categories[key].backImageUrl;
    const number = key;
    return { name, imageUrl, number, backImageUrl };
  });
  

  if (!filter) {
    return allCatgories;
  }
  return allCatgories.filter((category) => !filter.includes(parseInt(category.number)));
};

export { getSeriesByCategory, getMapCategory, getCategoryFieldById };
