import { sortBy, map, flatten, first, get, last } from 'lodash/fp';
import nestedNavs from '../assests/conts/nestedNavs';

const getSeriesByCategory = (series, categoryName) => {
  return sortBy(
    'order',
    series.filter((item) => item.categories.includes(categoryName))
  );
};
const getLessonsForSearch = (series, categories) => {
  let lessons = map((serie) => {
    const categoryName = first(serie.categories);
    const catgoryObj = categories.find((category) => category.name === categoryName);
    const categoryId = get('number', catgoryObj);
    return map((lesson) => {
      return {
        serieName: serie.name,
        serieId: serie._id,
        lessonOrder: lesson.order,
        weeklyLesson: lesson.weeklyLesson,
        lessonName: lesson.name,
        categoryId,
      };
    }, serie.lessonIds);
  }, series);
  lessons = flatten(lessons);

  lessons = map((lesson) => {
    const url = `/serie/${lesson.categoryId}/${lesson.serieId}/${lesson.lessonOrder} `;
    const name = lesson.lessonName ? `${lesson.serieName} - ${lesson.lessonName}` : lesson.serieName;
    return { url, name, weeklyLesson: lesson.weeklyLesson };
  }, lessons);

  return lessons;
};
const getPageAndActiveLesson = (series, serieId, order) => {
  const serie = series.find((serie) => serie._id === serieId);
  if (!serie) {
    return { serie: null, activeLesson: null };
  }
  const activeLesson = serie.lessonIds.find((item) => item.order === order);
  return { serie, activeLesson };
};

const getOrder = (serie) => {
  const lessons = sortBy('order', serie.lessonIds);
  return serie.showLastLesson ? get('order', last(lessons)) : get('order', first(lessons));
};
const getWeeklyUrl = (series, categories) => {
  const allLessons = getLessonsForSearch(series, categories);
  const weeklyLesson = allLessons.find((lesson) => lesson.weeklyLesson);
  if(!weeklyLesson){
    return ""
  }
  return weeklyLesson.url;
};
const getLessonsForNav = (serie, categoryId) => {
  const serieName = get('name', serie);
  const serieId = get('_id', serie);
  const lessons = serie.lessonIds.map((item) => ({
    ...item,
    navUrl: `/serie/${categoryId}/${serieId}/${item.order}`,
  }));
  const nav = nestedNavs.find((nav) => nav.name === serieName);
  if (!nav) {
    return { lessons, nested: false };
  }
  const splitLessons = [];
  const { parts } = nav;
  for (let part of parts) {
    const { name, start, end } = part;
    const items = lessons.slice(start - 1, end);
    const partWithItems = { name, items, start, end };
    splitLessons.push(partWithItems);
  }

  return { lessons: splitLessons, nested: true };
};
const getSelectedPartName = (items, selectedItemNumber) => {
  const selectedPart = items.find((item) => item.start <= selectedItemNumber && item.end >= selectedItemNumber);
  return get('name', selectedPart);
};

const getFilteredLessons = (text, data) => {
  if (!text) {
    return [];
  }
  return data.filter((serie) => serie.name.includes(text));
};

export {
  getSeriesByCategory,
  getLessonsForSearch,
  getFilteredLessons,
  getPageAndActiveLesson,
  getWeeklyUrl,
  getLessonsForNav,
  getSelectedPartName,
  getOrder,
};
