import { useState, useContext } from 'react';
import { MainContext } from '../context';
import { getPageAndActiveLesson } from './lessonFilter';

const useData = (serieId, order) => {
  const { loadData, data } = useContext(MainContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { serie, activeLesson } = getPageAndActiveLesson(data.series, serieId, order);

  const updateData = async () => {
    setLoading(true);
    await loadData(true);
    setLoading(false);
  };
  if (!activeLesson && !dataLoaded) {
    setDataLoaded(true);
    updateData();
  }

  return { serie, activeLesson, loading };
};
export default useData;
