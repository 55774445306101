import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { MainContext } from '../../context';
import { getSeriesByCategory, getOrder } from '../../utils/lessonFilter';
import Text from '../text';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  topContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '100px',
    position: 'relative',
  },
  title: {
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/titleBack.webp)`,
    backgroundSize: 'cover',
    position: 'absolute',
    top: '-35px',
    width: '320px',
    height: '70px',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '270px',
    },
  },
  caruselCon: {
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/caruselBackDes.webp)`,
    backgroundSize: 'cover',
    paddingTop: '100px',
    boxSizing: 'border-box',
    width: '100%',
    height: '400px',
    backgroundPosition: 'center',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/caruselBackMob.webp)`,
      height: '340px',
      paddingTop: '80px',
    },
  },
  subTitle: {
    padding: '10px',
  },
  caruselContainer: {
    margin: '50px 200px',
    [theme.breakpoints.down('md')]: {
      margin: '30px',
    },
  },

  card: {
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: '0px 20px',
  },
}));

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 4,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 600,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 600,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
};

const Carusel = ({ carusel }) => {
  const classes = useStyles();
  const { data } = useContext(MainContext);
  const recommendedSeries = getSeriesByCategory(data.series, 'חדשים ומומלצים');
  return (
    <div className={classes.topContainer}>
      <div className={classes.title}>
        <Text color="white" size="lg" fontWeight={600}>
          חדשים ומומלצים
        </Text>
      </div>
      <div className={classes.caruselCon}>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay={false}
          autoPlaySpeed={1000}
          centerMode={false}
          containerClass="container-with-dots"
          draggable
          focusOnSelect={false}
          infinite={true}
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          showDots={false}
          slidesToSlide={1}
          swipeable
        >
          {recommendedSeries.map((serie, index) => {
            const order = getOrder(serie)
            return (
              <Link
                className={classes.card}
                style={{ backgroundImage: `url(${serie.imageUrl})` }}
                key={index}
                to={`/serie/1/${serie._id}/${order}`}
              >
                <div className={classes.subTitle}>
                  <Text textShadow color="white" size="md" fontWeight={600}>
                    {serie.name}
                  </Text>
                </div>
              </Link>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Carusel;
