import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: ({ color, size, textShadow, fontWeight, fontFamily, textAlign, textDecoration }) => {
    const themeColor =
      color === 'white'
        ? theme.palette.primary.white
        : color === 'black'
        ? theme.palette.primary.black
        : color === 'blue'
        ? theme.palette.primary.blue
        : color === 'gray'
        ? theme.palette.primary.gray
        : color === 'yellow'
        ? theme.palette.primary.yellow
        : color ? color : theme.palette.primary.black;
    const desSize =
      size === 'xxs'
        ? theme.palette.fontsDes.xxs
        : size === 'xs'
        ? theme.palette.fontsDes.xs
        : size === 'sm'
        ? theme.palette.fontsDes.sm
        : size === 'md'
        ? theme.palette.fontsDes.md
        : size === 'lg'
        ? theme.palette.fontsDes.lg
        : size === 'xl'
        ? theme.palette.fontsDes.xl
        : size === 'xxl'
        ? theme.palette.fontsDes.xxl
        : theme.palette.fontsDes.md;
    const mobSize =
      size === 'xxs'
        ? theme.palette.fontsMobile.xxs
        : size === 'xs'
        ? theme.palette.fontsMobile.xs
        : size === 'sm'
        ? theme.palette.fontsMobile.sm
        : size === 'md'
        ? theme.palette.fontsMobile.md
        : size === 'lg'
        ? theme.palette.fontsMobile.lg
        : size === 'xl'
        ? theme.palette.fontsMobile.xl
        : size === 'xxl'
        ? theme.palette.fontsMobile.xxl
        : theme.palette.fontsMobile.md;

    return {
      color: themeColor,
      fontSize: desSize,
      textAlign: textAlign ? textAlign : 'center',
      textShadow: textShadow ? theme.palette.textShadow : 'none',
      fontWeight: fontWeight ? fontWeight : 'normal',
      fontFamily: fontFamily ? fontFamily : 'Heebo, sans-serif',
      textDecoration: textDecoration ? textDecoration : 'none',
      [theme.breakpoints.down('sm')]: {
        fontSize: mobSize,
      },
    };
  },
}));

const Text = ({ children, color, size, textShadow, fontWeight, fontFamily, textAlign, textDecoration }) => {
  const classes = useStyles({ color, size, textShadow, fontWeight, fontFamily, textAlign, textDecoration });
  return <div className={classes.title}>{children}</div>;
};

export default Text;
