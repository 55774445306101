const nestedNavs = [
  {
    name: 'תעס יומי',
    parts: [
      { start: 1, end: 29, name: 'חלק א' },
      { start: 30, end: 76, name: 'חלק ב' },
      { start: 77, end: 155, name: 'חלק ג' },
      { start: 156, end: 230, name: 'חלק ד' },
      { start: 231, end: 350, name: 'חלק ה' },
    ],
  },
];

export default nestedNavs;
