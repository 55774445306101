const text = {
  yazhiru: {
    title: 'ארגון אורות',
    p1: 'ארגון שפועלו ומטרתו קרוב הלבבות בין אדם לחברו, בין אדם לקב"ה ובין אדם לעצמו, מתוך הבנה ואמונה שכל אלו הינם למעשה דבר אחד',
    p2: 'קרוב הלבבות בכח אור התורה ואור תורת הסוד בדרכו של בעל הסולם זצ"ל, לימוד והדרכה מעשית בתורת ההתבוננות היהודית',
    p3: 'הארגון שייך לקבוצת "האומה" ופועל בהדרכתו והוראותיו של נשיא תנועת "האומה" הרב מרדכי שיינברגר שליט"א ',
    p4: 'בראש אורות עומד הרב אסף משה בילר',
    backgroundImage: 'https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/about/yazhiru.webp',
  },
  yeshiva: {
    title: 'ישיבת אור הגנוז',
    p1: 'ישיבת אור הגנוז לבעלי תשובה  הינה ישיבה חדשה וייחודית שמביאה איתה רוח חדשה לעולם הישיבות, אנחנו משלבים לימוד תורת הנגלה והנסתר יחד עם התנדבות ועבודה קהילתית במגוון פרויקטים ביישוב.',
    p2: 'הישיבה הוקמה מתוך מחשבה להוות מקום של התפתחות רוחנית , מקום שמאחד יחד אנשים שרוצים להתחבר אחד לשני, להתחבר לשם יתברך, לתורה הקדושה ולעם ישראל',
    link: 'http://yeshivaor.co.il',
    details: 'לפרטים נוספים, שמואל - 054-4468346',
  },
  sadnaot: {
    title: 'סדנאות',
    veitbonanta: {
      title: 'סדנת והתבוננת',
      text: `סדנת תרגול מעשי ולימוד . שמונה מפגשים של שלוש שעות כל מפגש - לימוד מעמיק של שיטת "והתבוננת"`,
      backgroundImage: 'https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/about/veitbonant.jpg',
      link: 'https://bt.elima.org.il/%D7%95%D7%94%D7%AA%D7%91%D7%95%D7%A0%D7%A0%D7%AA/',
    },
    dmamaDaka: {
      title: 'סדנת דממה דקה',
      text: 'סדנת שתיקה ולימוד התבוננות מעשי. חמישה ימים בארוח מלא - עבודה פנימית עמוקה ומשמעותית',
      backgroundImage: 'https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/about/dmamaDaka.jpg',
      link: "https://yazhiru.ravpage.co.il/DmamaDaka2020"
    },
  },
  tfilot: {
    title: 'גמ"ח תפילות',
    text: `מרכז תפילות לזיכוי הרבים, מופעל ע"י אברכי כולל אשמורת, ובו אנו מתפללים בעבור כל אחינו ישראל, ובפרט בקשות מיוחדות לזיווג ,פרנסה ,רפואה וכד' לטובת כל מבקשי הישועות.  לימוד ואמירת קדיש לעילוי נשמת נפטרים`,
    backgroundImage: 'https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/about/tfilot.jpg',
    link: "/pray"

  },
  shabatot: {
    title: 'שבתות אירוח',
    text: 'שיעורים בנושאים מגוונים מרבני הישוב , סעודות כיד המלך ,תפילות משותפות , שהות ולינה באווירה של קדושה למרגלות הר מירון',
    backgroundImage: 'https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/about/shabatot.webp',
    link: "http://yeshivaor.co.il/contact"

  },
};
export default text;
