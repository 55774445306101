import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../components/text';
import Header from '../components/header';
import partnersText from '../assests/texts/partners';
import Button from '../components/button';


const useStyles = makeStyles((theme) => ({
  topContainer: {
    direction: 'rtl',
  },

  bannerCon: {
    margin: '60px 40px',
  },
  textCon: {
    margin: '5px 0px',
    textAlign: "center"
  },
  linkCon: {
    width: '100%',
    margin: '25px 0px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {

    },
  },
}));

const Partners = () => {
  const classes = useStyles();
  const mobile = window.innerWidth < 500;
  const imageUrl = mobile ? 'https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/system/shutafutMob.jpg' : 'https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/system/shutafut.jpg';

  const donate = async () => {
    window.open(partnersText.link, '_blank');
  };

  return (
    <div className={classes.topContainer}>
      <Header imageUrl={imageUrl}>
      </Header>
      <div className={classes.bannerCon}>
        <div className={classes.textCon}>
          <Text textAlign="center" size="xxl" fontWeight={600}>
            {partnersText.subTitle1}
          </Text>
        </div>
        <div className={classes.textCon}>
          <Text textAlign="center" size="lg" fontWeight={500}>
            {partnersText.subTitle2}
          </Text>
        </div>
        <div className={classes.textCon}>
          <Text textAlign="center" size="md" fontWeight={500}>
            {partnersText.subTitle3}
          </Text>
        </div>
        <div className={classes.linkCon}>
          <Button btnColor="yellow" onClick={donate}>
            <Text size="sm">{partnersText.donate}</Text>
          </Button>
        </div>
        <div className={classes.textCon}>
          <Text textAlign="center" size="md" fontWeight={500}>
            {partnersText.subTitle4}
          </Text>
        </div>
        <div className={classes.textCon}>
          <Text textAlign="center" size="md" fontWeight={600}>
            {partnersText.subTitle5}
          </Text>
        </div>
        <div className={classes.textCon}>
          <Text textAlign="center" size="md" fontWeight={500}>
            {partnersText.bank}
          </Text>
        </div>
        <div className={classes.textCon}>
          <Text textAlign="center" size="md" fontWeight={500}>
            {partnersText.snif}
          </Text>
        </div>
        <div className={classes.textCon}>
          <Text textAlign="center" size="md" fontWeight={500}>
            {partnersText.account}
          </Text>
        </div>
        <div className={classes.textCon}>
          <Text textAlign="center" size="md" fontWeight={500}>
            {partnersText.name}
          </Text>
        </div>
        <div className={classes.textCon}>
          <Text textAlign="center" size="md" fontWeight={500}>
            {partnersText.write}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Partners;
