import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#fff',
    borderRadius: '50%',
    height: '70px',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
    },
  },
  icon: {
    fontSize: '70px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '50px',
    },
  },
}));

const PlayButton = ({ url }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <PlayArrowIcon className={classes.icon} />
    </div>
  );
};

export default PlayButton;
