import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {VolunteerActivism} from '@mui/icons-material';
import Text from '../../components/text';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    justifyContent:"flex-end",
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5px',
      justifyContent:"flex-start",
    },
  },
  icon: {
    fontSize: '20px',
    marginLeft: '5px',
    color: '#429acc',
  },
}));

const DonateLink = ({ text, link }) => {
  const classes = useStyles();

  return (
    <Link className={classes.link} to={{ pathname: link }} target="_blank">
      <VolunteerActivism className={classes.icon} />
      <Text textDecoration="underline" size="sm">
        {text}
      </Text>
    </Link>
  );
};

export default DonateLink;
