import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: ({ btnColor, borderColor, width, backgroundImage }) => {
    return {
      cursor: 'pointer',
      backgroundColor:
        btnColor === 'yellow'
          ? theme.palette.primary.yellow
          : btnColor === 'blue'
          ? theme.palette.primary.blue
          : 'transparent',
      backgroundImage: backgroundImage ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))` : 'none',
      border: !borderColor ? 'none' : `1px solid ${borderColor}`,
      borderRadius: '50px',
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      width: width ? width : 'fit-content',
      padding: '5px 20px',
      boxShadow: ` rgba(0, 0, 0, 1) 0px 3px 8px;`,
    };
  },
}));

const Button = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.button} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export default Button;
