import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Text from '../text';

const useStyles = makeStyles((theme) => ({
  topContainer: {
    position: 'absolute',
    zIndex: 20,
    width: '100%',
    display: 'flex',
    direction: 'rtl',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px',

      height: 'auto',
      boxSizing: 'border-box',
      border: 'none',
    },
  },
  logo: {
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/images/orotLogo.png)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    right: '10px',
    top: '-13px',
    position: 'absolute',
    width: '175px',
    height: '100px',
    [theme.breakpoints.down('sm')]: {
      right: 'calc(50% - 87px)',
    },
  },
  linksDes: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileBtn: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/mobileBurger.svg)`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: '25px',
      height: '25px',
      position: 'absolute',
      right: '15px',
      top: '18px',
    },
  },
  link: {
    direction: 'rtl',
    margin: '0px 15px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px',
      padding: '10px 20px',
    },
  },
  drawerPaper: {
    width: '70%',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  linksMob: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    // backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/weeklyLesson.webp)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // backgroundColor: theme.palette.primary.main,
    paddingTop: '20px',
  },

  clearIcon: {
    position: 'absolute',
    left: '5px',
    top: '5px',
    fontSize: '30px',
  },

  activeLink: {
    direction: 'rtl',
    margin: '0px 15px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      color: '#000',
      margin: '0px',
      padding: '10px 20px',
    },
  },
}));

const PagesNav = ({ isOpen, setIsOpen }) => {
  const location = useLocation();

  const isActive = (linkTo) => {
    return linkTo === location.pathname || (linkTo !== '/' && location.pathname.includes(linkTo));
  };
  const openDrawer = () => {
    setIsOpen(true);
  };

  const classes = useStyles();
  return (
    <div className={classes.topContainer}>
      <Link className={classes.logo} to={`/`}/>
      <div className={classes.mobileBtn} onClick={openDrawer}></div>
      <Drawer
        open={isOpen}
        variant="persistent"
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.linksMob}>
          <ClearIcon className={classes.clearIcon} />

          <Link to={`/`} className={isActive('/') ? classes.activeLink : classes.link}>
            <Text
              textAlign="initial"
              size="md"
              color={isActive('/') ? '#d5cd0f' : 'black'}
              fontWeight={isActive('/') ? 500 : 400}
            >
              דף הבית
            </Text>
          </Link>
          <Link to={`/series/0`} className={isActive('/series') ? classes.activeLink : classes.link}>
            <Text
              textAlign="initial"
              size="md"
              color={isActive('/series') ? '#d5cd0f' : 'black'}
              fontWeight={isActive('/series') ? 500 : 400}
            >
              כל השיעורים
            </Text>
          </Link>
          <Link to={`/partners`} className={isActive('/partners') ? classes.activeLink : classes.link}>
            <Text
              textAlign="initial"
              size="md"
              color={isActive('/partners') ? '#d5cd0f' : 'black'}
              fontWeight={isActive('/partners') ? 500 : 400}
            >
              שותפות
            </Text>
          </Link>
          <Link to={`/about`} className={isActive('/about') ? classes.activeLink : classes.link}>
            <Text
              textAlign="initial"
              size="md"
              color={isActive('/about') ? '#d5cd0f' : 'black'}
              fontWeight={isActive('/about') ? 500 : 400}
            >
              אודות
            </Text>
          </Link>
        </div>
      </Drawer>

      <div className={classes.linksDes}>
        <Link to={`/`} className={isActive('/') ? classes.activeLink : classes.link}>
          <Text size="md" fontWeight={600} textShadow color={isActive('/') ? 'yellow' : 'white'}>
            דף הבית
          </Text>
        </Link>
        <Link to={`/series/0`} className={isActive('/series') ? classes.activeLink : classes.link}>
          <Text size="md" fontWeight={600} textShadow color={isActive('/series') ? 'yellow' : 'white'}>
            כל השיעורים
          </Text>
        </Link>
        <Link to={`/partners`} className={isActive('/partners') ? classes.activeLink : classes.link}>
          <Text size="md" fontWeight={600} textShadow color={isActive('/partners') ? 'yellow' : 'white'}>
            שותפות
          </Text>
        </Link>
        <Link to={`/about`} className={isActive('/about') ? classes.activeLink : classes.link}>
          <Text size="md" fontWeight={600} textShadow color={isActive('/about') ? 'yellow' : 'white'}>
            אודות
          </Text>
        </Link>
      </div>
    </div>
  );
};

export default PagesNav;
