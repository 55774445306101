import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import taas from '../../assests/images/taas.pdf';
import { last } from 'lodash/fp';
import { SERVER_URL } from '../../assests/conts';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Text from '../text';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  icon: {
    fontSize: '20px',
    marginLeft: '5px',
    color: theme.palette.primary.main,
  },
}));

const TaasLink = ({ url }) => {
  const classes = useStyles();

  const downloadTaas = async () => {
    const file = last(taas.split('/'));
    const link = `${SERVER_URL}/download/${file}`;
    window.open(link);
  };

  return (
    <div className={classes.link} variant="contained" color="primary" onClick={downloadTaas}>
      <NoteAddIcon className={classes.icon} />
      <Text textDecoration="underline" size="sm">
        מסמך תע״ס להורדה
      </Text>
    </div>
  );
};

export default TaasLink;
