import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Text from '../text';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
  desContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    maxHeight: '470px',
    overflow: 'auto',
    direction: 'rtl',
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/weeklyLesson.webp)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      direction: 'rtl',
      position: 'relative',
      width: '100%',
      zIndex: 2,
    },
  },
  menu: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    top: '0px',
    overflow: 'hidden',
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/weeklyLesson.webp)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
  },
  selectTitleIcon: {
    color: theme.palette.primary.white,
    fontSize: '30px',
    position: 'absolute',
    left: '10px',
  },
  list: {
    maxHeight: '400px',
    overflow: 'scroll',
  },
  link: {
    padding: '10px 0px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  selectTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    position: 'relative',
    width: "100%"
  },
}));

const MainNav = ({ title, items, selectedItemNumber }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const height = open ? 'auto' : 50;
  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <div className={classes.desContainer}>
        {items.map((item, index) => {
          const active = item.number === selectedItemNumber || item.order === selectedItemNumber;
          return (
            <Link to={item.navUrl} key={index} className={classes.link}>
              <Text color={active ? 'yellow' : 'white'} size="md" fontWeight={active ? 800 : 400}>
                {item.name}
              </Text>
            </Link>
          );
        })}
      </div>
      <div className={classes.mobContainer}>
        <div style={{ height }} className={classes.menu}>
          <div className={classes.selectTitle} onClick={toggleMenu}>
            {!open && (
              <Text color="yellow" size="md" fontWeight={800}>
                {title}
              </Text>
            )}

            {open ? (
              <KeyboardArrowUp fontSize="large" className={classes.selectTitleIcon} />
            ) : (
              <KeyboardArrowDown fontSize="large" className={classes.selectTitleIcon} />
            )}
          </div>
          <div className={classes.list}>
            {items.map((item, index) => {
              const active = item.number === selectedItemNumber || item.order === selectedItemNumber;
              return (
                <Link to={item.navUrl} key={index} className={classes.link} onClick={toggleMenu}>
                  <Text color={active ? 'yellow' : 'white'} size="md" fontWeight={active ? 800 : 400}>
                    {item.name}
                  </Text>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainNav;
