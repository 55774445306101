const text = {
  subTitle1: 'בזכותכם מגדילים תורה!',
  subTitle2: 'תרמו עכשיו לאורות ועזרו לנו להמשיך ולהפיץ את אור האמונה בעולם!',
  subTitle3: "קהילת אורות הינה ארגון ללא מטרות רווח. המטרה שלנו היא להפיץ את אור האמונה בעולם. השתתפו איתנו בהפצת הבשורה באמצעות תרומה לפעילות.",
  subTitle4: "בעמוד התרומות ניתן להעביר שמות לתפילה עליהם יתפלל הרב ותלמידיו.",
  subTitle5: "לתרומה בהעברה בנקאית:",
  bank: "בנק הבינלאומי (31)",
  snif: "סניף 001 (צפת)",
  account: "מספר חשבון 288136",
  name: "ע״ש גנזי שמעון",
  write: "נא לציין בסיבת ההעברה ״עבור ארגון אורות״",
  link: 'https://www.peach-in.com/cmp/LQBP8bp7y',
  donate: 'תרום עכשיו!',
};
export default text;
