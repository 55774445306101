import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import homeText from '../../assests/texts/home';
import Text from '../text';
import { getWeeklyUrl } from '../../utils/lessonFilter';
import { MainContext } from '../../context';
import { Link } from 'react-router-dom';
import { getMapCategory } from '../../utils/categoryFilter';

const useStyles = makeStyles((theme) => ({
  topContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  mainContainer: {
    height: '400px',
    width: '100%',
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '180px',
    },
  },

  skyContainer: {
    position: 'absolute',
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/weeklyLesson.webp)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    left: '0px',
    right: '0px',
    bottom: '100px',
    top: '100px',
    zIndex: -1,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      bottom: '45px',
      top: '45px',
    },
  },
  title: {
    marginBottom: '20px',
  },

  imageContainer: {
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/system/shiurShvui.png)`,
    zIndex: 2,
    cursor: 'pointer',
    height: '100%',
    width: '670px',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundPosition: 'center',
    backgroundPositionY: '0px',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      width: '300px',
    },
  },
  playLogo: {
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/videoPlay.svg)`,
    width: '70px',
    height: '70px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      height: '50px',
    },
  },
}));

const WeekLesson = () => {
  const classes = useStyles();
  const { data } = useContext(MainContext);
  const { series, categories } = data;
  const allCategories = getMapCategory(categories);
  const lesssonUrl = getWeeklyUrl(series, allCategories);

  return (
    <div className={classes.topContainer}>
      <div className={classes.title}>
        <Text color="black" size="lg" fontWeight={600}>
          {homeText.shiurSvueiTitle}
        </Text>
      </div>

      <div className={classes.mainContainer}>
        <Link to={lesssonUrl} className={classes.imageContainer}>
          <div className={classes.playLogo}></div>
        </Link>
        <div className={classes.skyContainer}></div>
      </div>
    </div>
  );
};

export default WeekLesson;
