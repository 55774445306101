const text = {
  yazhiru: 'אורות',
  ravName: 'הרב אסף משה בילר',
  orotTitle1: 'ללמוד | לקיים | לחיות',
  orotTitle2: 'לומדים חכמת הקבלה, רוחניות וחסידות',
  orotTitle3: 'בשבילים בדרך הביתה',
  shiurSvueiTitle: 'השיעור השבועי',
  shiurSvueiDes: 'התבוננות שבועית לאור פנימיות התורה',
  allRights: 'כל הזכויות שמורות לאורות - שבילים בדרך הביתה',
  whatsupp: 'https://chat.whatsapp.com/LOcRsCtgTlEALCRtd1FtLG',
};
export default text;
