import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import homeText from '../../assests/texts/home';
import Text from '../text';

const useStyles = makeStyles((theme) => ({
  headerCon: {
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/desktopCover.jpg)`,
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    color: '#fff',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/mobileCover.jpg)`,
    },
  },
  textCon: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paragraph: {

  },

  scrollArrow: {
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/blueScrollArrow.svg)`,
    width: '55px',
    height: '60px',
    backgroundColor: '#fff',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    bottom: '0px',
    borderTopLeftRadius: '50%',
    borderTopRightRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    borderBottom: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '50px',
      height: '55px',
    },
  },
}));

const HomeHeader = () => {
  const classes = useStyles();
  const height = window.innerHeight;
  const mobile = window.innerWidth < 500;
  return (
    <div className={classes.headerCon} style={{ height }}>
      <div className={classes.textCon}>
        <div className={classes.paragraph}>
          <Text color="white" size={mobile ? "lg" : "xxl"} fontWeight={mobile ? 500 : 600} >
            {homeText.orotTitle1}
          </Text>
        </div>
        <div className={classes.paragraph}>
          <Text color="white" size={mobile ? "md" : "xl"} fontWeight={mobile ? 400 : 600} >
            {homeText.orotTitle2}
          </Text>
        </div>
        <div className={classes.paragraph}>
          <Text color="white" size={mobile ? "md" : "xl"} fontWeight={mobile ? 400 : 600} >
            {homeText.orotTitle3}
          </Text>
        </div>
      </div>
      <div className={classes.scrollArrow}></div>
    </div>
  );
};

export default HomeHeader;
