import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../text';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginTop: '10px',
    borderBottom: `1px solid ${theme.palette.primary.black}`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  name: {
    width: '150px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  date: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '10px',
    },
  },
  text: {
    marginRight: '20px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
    },
  },
}));

const CommentList = ({ comments }) => {
  const classes = useStyles();

  return (
    <div className={classes.topContainer}>
      {comments.map((comment) => {
        const day = moment(comment.date).format('l');
        return (
          <div key={comment._id} className={classes.container}>
            <div className={classes.title}>
              <div className={classes.name}>
                <Text size="sm" textAlign="start" fontWeight={500}>
                  {comment.name}
                </Text>
              </div>
              <div className={classes.date}>
                <Text size="xxs" fontWeight={300}>
                  {day}
                </Text>
              </div>
            </div>
            <div className={classes.text}>
              <Text textAlign="initial" size="sm">
                {comment.text}
              </Text>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CommentList;
