import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getLessonsForSearch, getFilteredLessons } from '../utils/lessonFilter';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Text from './text';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    direction: 'rtl',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    margin: '20px',
    marginBottom: '0px',
    borderBottom: `2px solid ${theme.palette.primary.white}`,
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
    },
  },
  mainContainer: {
    width: '500px',
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/weeklyLesson.webp)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  topContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    top: '0px',
    bottom: '0px',
    zIndex: 10,
    left: '0px',
    right: '0px',
    paddingTop: '30px',
    backgroundColor: 'rgb(255, 255, 255, 0.95)',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
    },
  },
  listContainer: {
    height: 'calc(100% - 50px)',
    overflow: 'scroll',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 67px)',
    },
  },
  listItem: {
    paddingTop: '5px',
    paddingRight: '20px',
    paddingleft: '20px',
    cursor: 'pointer',
  },
  searchIcon: {
    fontSize: theme.palette.fontsDes.md,
    color: theme.palette.primary.white,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.palette.fontsMobile.md,
    },
  },
  exit: {
    position: 'absolute',
    left: '5px',
    top: '0px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      left: '0px',
    },
  },
  clearIcon: {
    fontSize: '30px',
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.primary.white,
    },
  },
  input: {
    width: '100%',
    outline: 'none',
    color: theme.palette.primary.white,
    fontSize: theme.palette.fontsDes.md,
    backgroundColor: 'transparent',
    border: 'none',
    '&::placeholder': {
      color: theme.palette.primary.white,
      opacity: '0.5',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.palette.fontsMobile.md,
    },
  },
}));

const SearchLesson = ({ series, categories, onExit }) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const history = useHistory();
  const allLessons = useMemo(() => getLessonsForSearch(series, categories), [series, categories]);
  const filterLessons = getFilteredLessons(text, allLessons);

  const editField = (event) => {
    const { value } = event.target;
    setText(value);
  };
  const goToLesson = (url) => {
    history.push(url);
  };

  return (
    <div className={classes.topContainer}>
      <div className={classes.exit} onClick={onExit}>
        <ClearIcon className={classes.clearIcon} />
      </div>

      <div className={classes.mainContainer}>
        <div className={classes.fieldContainer}>
          <input
            autoFocus
            onChange={editField}
            placeholder="חפש שיעור או סדרה"
            value={text}
            className={classes.input}
          />
          <SearchIcon className={classes.searchIcon} />
        </div>
        <div className={classes.listContainer}>
          {filterLessons.map((item) => {
            return (
              <div key={item.url} className={classes.listItem} onClick={() => goToLesson(item.url)}>
                <Text textAlign="initial" size="sm" color="white" fontWeight={500}>
                  {item.name}
                </Text>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SearchLesson;
