import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerCon: ({ imageUrl, height, imageMobUrl }) => {
    return {
      backgroundImage: `url(${imageUrl})`,
      width: '100%',
      height: height ? height : '400px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      color: '#fff',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      [theme.breakpoints.down('sm')]: {
        height: '200px',
      },
    };
  },
}));

const Header = ({ imageUrl, imageMobUrl, children, height }) => {
  const classes = useStyles({ imageUrl, height, imageMobUrl });
  return <div className={classes.headerCon}>{children}</div>;
};

export default Header;
