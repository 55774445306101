import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Text from '../text';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { getSelectedPartName } from '../../utils/lessonFilter';

const useStyles = makeStyles((theme) => ({
  mobContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      direction: 'rtl',
      position: 'relative',
      width: '100%',
      zIndex: 2,
    },
  },
  desContainer: {
    maxHeight: '470px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menu: {
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/weeklyLesson.webp)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
  selectTitleIcon: {
    color: theme.palette.primary.white,
    fontSize: '30px',
    position: 'absolute',
    left: '10px',
  },
  list: {
    maxHeight: '400px',
    overflow: 'scroll',
  },
  link: {
    padding: '10px 0px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  selectTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    position: 'relative',
  },
}));

const NestedNav = ({ title, items, selectedItemNumber }) => {
  const classes = useStyles();
  const [openIndex, setOpenIndex] = useState(null);
  const [openTop, setOpenTop] = useState(null);
  const selectedPartName = getSelectedPartName(items, selectedItemNumber);

  const toggleMenu = (index) => {
    if (openIndex !== null) {
      setOpenIndex(null);
      setOpenTop(false);
      return;
    }
    setOpenIndex(index);
  };

  const openTopCon = () => {
    if (!openTop) {
      setOpenTop(true);
    }
  };

  const getNestedNav = () => {
    return items.map((item, index) => {
      const open = openIndex === index;
      const activePart = selectedPartName === item.name;
      return (
        <div
          key={index}
          style={{ height: open ? 'auto' : 50 }}
          height={open ? 'auto' : 50}
          className={classes.menu}
          onClick={() => toggleMenu(index)}
        >
          <div className={classes.selectTitle}>
            {!open && (
              <Text color={activePart ? 'yellow' : 'white'} size="md" fontWeight={800}>
                {item.name}
              </Text>
            )}

            {open ? (
              <KeyboardArrowUp fontSize="large" className={classes.selectTitleIcon} />
            ) : (
              <KeyboardArrowDown fontSize="large" className={classes.selectTitleIcon} />
            )}
          </div>
          <div className={classes.list}>
            {item.items.map((item, index) => {
              const active = item.number === selectedItemNumber || item.order === selectedItemNumber;
              return (
                <Link to={item.navUrl} key={index} className={classes.link}>
                  <Text color={active ? 'yellow' : 'white'} size="md" fontWeight={active ? 800 : 400}>
                    {item.name}
                  </Text>
                </Link>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <div className={classes.desContainer}>{getNestedNav()}</div>
      <div className={classes.mobContainer}>
        <div style={{ height: openTop ? 'auto' : 50 }} className={classes.menu} onClick={openTopCon}>
          {!openTop && (
            <div className={classes.selectTitle}>
              <Text color="yellow" size="md" fontWeight={800}>
                {selectedPartName}
              </Text>
              <KeyboardArrowDown fontSize="large" className={classes.selectTitleIcon} />
            </div>
          )}
          <div className={classes.list}>{getNestedNav()}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NestedNav;
