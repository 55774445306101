import React, { useState, createContext, useEffect } from 'react';
import { SERVER_URL } from '../assests/conts';
import axios from 'axios';
import Loader from '../components/loader';

export const MainContext = createContext();

const MainProvider = (props) => {
  const [data, setData] = useState(false);
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (newData) => {
    const config = newData
      ? {
          headers: {
            'Cache-Control': 'no-cache',
          },
        }
      : {};
    const seriesUrl = `${SERVER_URL}/api/series/getSeries`;
    const categoriesUrl = `${SERVER_URL}/api/categories/getCategories`;
    const promises = [axios.get(seriesUrl, config), axios.get(categoriesUrl, config)];

    const [series, categories] = (await Promise.all(promises)).map((item) => item.data);

    setData({ series, categories });
  };

  if (!data) {
    return <Loader />;
  }

  return <MainContext.Provider value={{ data, loadData }}>{props.children}</MainContext.Provider>;
};
export default MainProvider;
