import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
    maxWidth: '750px',
    paddingBottom: '20px',
    paddingTop: '30px'
  },
  fieldContainer: {
    marginTop: '20px',
    width: '100%',
    direction: 'rtl',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    height: '50px',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '70px',
    },
  },
}));

const CommentForm = ({ onSend }) => {
  const classes = useStyles();
  const [info, setInfo] = useState({ name: '', text: '' });

  const editField = (field) => (event) => {
    const { value } = event.target;
    setInfo({ ...info, [field]: value });
  };
  const enable = info.name && info.text;

  return (
    <div className={classes.formContainer}>
      <div className={classes.fieldContainer}>
        <TextField onChange={editField('name')} value={info.name} fullWidth placeholder="שם מלא" required />
      </div>
      <div className={classes.fieldContainer}>
        <TextField
          onChange={editField('text')}
          value={info.text}
          multiline
          fullWidth
          variant="outlined"
          rows={4}
          placeholder="תוכן התגובה"
          required
        />
      </div>
      <div className={classes.fieldContainer}>
        <Button disabled={!enable} onClick={() => onSend(info)} className={classes.button} variant="contained" color="primary">
          הוסף תגובה
        </Button>
      </div>
    </div>
  );
};

export default CommentForm;
