import React, { useEffect, useMemo } from 'react';
import { get, toNumber } from 'lodash/fp';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Player from '../components/serie/player';
import useData from '../utils/useData';
import Loader from '../components/loader';
import MainNav from '../components/navs/mainNav';
import NestedNav from '../components/navs/nestedNav';
import Text from '../components/text';
import Header from '../components/header';
import { getLessonsForNav } from '../utils/lessonFilter';
import TaasLink from '../components/serie/taasLink';
import Comments from '../components/comments/index';
import footerText from '../assests/texts/footer';
import WhatsappLink from '../components/serie/whatsappLink';
import DonateLink from '../components/serie/donateLink';
import partnersText from '../assests/texts/partners';

const useStyles = makeStyles((theme) => ({
  container: {
    direction: 'rtl',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px',
    },
  },
  playerCon: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: '50px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'center',
      marginRight: '0px',
    },
  },
  navCon: {
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      margin: '20px',
      marginTop: '20px',
      marginBottom: '65px',
    },
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px',
    },
  },
  links: {
    display: 'flex',
    width: '800px',
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      flexDirection: 'column',
      marginTop: '5px',
    },
  },
  btnLink: {
    textDecoration: "underline",
    display: "flex"
  }
}));

const Serie = (props) => {
  const classes = useStyles();
  let { categoryId, serieId, order } = useParams();
  order = toNumber(order);
  const { serie, activeLesson, loading } = useData(serieId, order);
  const { lessons, nested } = useMemo(() => getLessonsForNav(serie, categoryId), [serie, categoryId]);
  const tabs = get('lessonIds.length', serie) > 1;
  const taas = get('name', serie) === 'תעס יומי';
  const history = useHistory();
  console.log(serie, "serie")
  useEffect(() => {
    if (!activeLesson && !loading) {
      history.push(`/series/0`);
    }
    if (activeLesson && activeLesson.name) {
      document.title = activeLesson.name;
    }
    return () => {
      document.title = 'הרב אסף משה בילר';
    };
  }, [activeLesson, loading, history]);

  const getNav = () => {
    if (!tabs) {
      return null;
    }
    if (tabs && !nested) {
      return <MainNav items={lessons} selectedItemNumber={activeLesson.order} title={activeLesson.name} />;
    }
    if (tabs && nested) {
      return <NestedNav items={lessons} selectedItemNumber={activeLesson.order} title={activeLesson.name} />;
    }
  };

  if (!activeLesson) {
    return <Loader />;
  }

  return (
    <div className={classes.container}>
      <Header imageUrl={serie.imageUrl} height="600px">
        <Text color="white" size="xxl" fontWeight={600}>
          {serie.name}
        </Text>
      </Header>

      <div className={classes.content}>
        <div className={classes.navCon}>{getNav()}</div>
        <div className={classes.playerCon}>
          <Player url={activeLesson.url} text={tabs ? activeLesson.name : null} />
          <div className={classes.links}>
            <WhatsappLink
              link={footerText.whatsappLink}
              text={"הצטרפו לוואטסאפ של ׳אורות׳"}
            />
            {taas && <TaasLink />}
            {!taas && <DonateLink link={partnersText.link} text="נהנתם? קחו חלק!" />}
          </div>
          {!taas && <Comments lessonId={activeLesson._id} />}
        </div>
      </div>
    </div>
  );
};

export default Serie;
