import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core/styles';
import PlayButton from './playButton';
import Text from '../text';

const useStyles = makeStyles((theme) => ({
  mediaContainer: {
    position: 'relative',
    height: 'fit-content'
  },
  title: {
    position: 'absolute',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '44px',
    top: '0px',
    paddingTop: '10px',
    height: '60px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      height: '55px',
    },
  },
}));

const Player = ({ url, text, customWidth, customHeight, light }) => {
  const classes = useStyles();
  let sound = null;
  if (url) {
    sound = url.toLowerCase().includes('mp3');
  }

  const [lowHeight, setLowHeight] = useState(false);
  const [playing, setPlaying] = useState(false);

  const setHeight = () => {
    setPlaying(true);
    if (sound) {
      setLowHeight(true);
    }
  };

  const mobile = window.innerWidth < 400;
  const heightSize = customHeight ? customHeight : lowHeight ? '100px' : mobile ? '180px' : '450px';
  const widthSize = customWidth ? customWidth : mobile ? '320px' : '800px'
  return (
    <div
      onClick={setHeight}
      className={classes.mediaContainer}
      style={{
        width: widthSize,
        backgroundColor: sound ? 'transparent' : '#000',
      }}
    >
      <div
        style={{
          width: widthSize,
        }}
        className={classes.title}
      >
        {!playing && (
          <Text color="white" size="lg" fontWeight={500}>
            {text}
          </Text>
        )}
      </div>

      <ReactPlayer
        style={{
          outline: 'none',
        }}
        width={widthSize}
        height={heightSize}
        config={{
          file: {
            attributes: {
              onContextMenu: (e) => e.preventDefault(),
              controlsList: 'nodownload',
            },
          },
          youtube: {
            preload: true,
            playerVars: { modestbranding: 1 },
          },
          vimeo: {
            preload: true,
          },
        }}
        playing={true}
        controls={true}
        playIcon={<PlayButton />}
        light={light ? light : 'https://orot.sfo3.cdn.digitaloceanspaces.com/images/black.png'}
        url={url}
      />
    </div>
  );
};

export default Player;
