import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Text from './text';
import { getOrder } from '../utils/lessonFilter';

const useStyles = makeStyles((theme) => ({
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    margin: '30px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  subTitle: {
    padding: '10px',
  },
  image: {
    width: '250px',
    margin: '0px 20px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '250px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '150px',
      height: '150px',
      margin: '0px 10px',
      marginBottom: '20px',
    },
  },
}));

const Cards = ({ data, categoryId }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <div className={classes.cards}>
      {data.map((item, index) => {
        let pathname = '';
        if (categoryId === '0') {
          pathname = `/series/${item.number}`;
        } else {
          const order = getOrder(item);
          pathname = `/serie/${categoryId}/${item._id}/${order}`;
        }
        return (
          <Link
            to={{ pathname, state: { prevPath: location.pathname } }}
            key={index}
            className={classes.image}
            style={{
              backgroundImage: ` url(${item.imageUrl})`,
            }}
          >
            <div className={classes.subTitle}>
              <Text textShadow color="white" size="md" fontWeight={600}>
                {item.name}
              </Text>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Cards;
