import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Button from '../button';
import Text from '../text';
import Dialog from '../dialog';
import CommentForm from './commentForm';
import { SERVER_URL } from '../../assests/conts';
import CommentList from './commentList';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '800px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '320px',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/weeklyLesson.webp)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
}));

const Comments = ({ lessonId }) => {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [comments, setComments] = useState([]);

  const loadComments = useCallback(
    async (newData) => {
      const config = newData
        ? {
          headers: {
            'Cache-Control': 'no-cache',
          },
        }
        : {};
      const url = `${SERVER_URL}/api/comments/getComments/${lessonId}`;
      const data = (await axios.get(url, config)).data;
      setComments(data);
    },
    [lessonId]
  );

  useEffect(() => {
    loadComments();
  }, [loadComments]);

  const addComment = async (info) => {
    setEditMode(false);
    const body = { ...info, lessonId };
    const url = `${SERVER_URL}/api/comments/postNewComment`;
    await axios.post(url, body);
    loadComments(true);
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Text size="md" fontWeight={600} color="white">
          תגובות
        </Text>
        <Button btnColor="yellow" className={classes.button} onClick={() => setEditMode(true)}>
          <Text size="sm"> + הוספת תגובה</Text>
        </Button>
      </div>
      <Dialog onClose={() => setEditMode(false)} open={editMode}>
        <CommentForm onSend={addComment} />
      </Dialog>

      <CommentList comments={comments} />
    </div>
  );
};

export default Comments;
