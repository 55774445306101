import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Home from './pages/home';
import MainProvider from './context';
import Partners from './pages/partners';
import Series from './pages/series';
import Serie from './pages/serie';
import About from "./pages/about";
import Footer from './components/footer';
import TopBar from './components/navs/pagesNav';
import ScrollToTop from './components/scrollToTop';

import './index.css';

const useStyles = makeStyles((theme) => ({
  topContainer: {
    position: 'relative',
  },
}));

const App = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const closeDrawer = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <MainProvider>
      <div onClick={closeDrawer} className={classes.topContainer}>
        <Router>
          <Switch>
            <Route>
              <ScrollToTop />
              <TopBar isOpen={isOpen} setIsOpen={setIsOpen} />
              <Route exact path="/" component={Home} />
              <Route exact path="/series/:categoryId" component={Series} />
              <Route exact path="/serie/:categoryId/:serieId/:order" component={Serie} />
              <Route exact path="/partners" component={Partners} />
              <Route exact path="/about" component={About} />
              <Footer />
            </Route>
          </Switch>
        </Router>
      </div>
    </MainProvider>
  );
};

export default App;
