import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../button';
import AnimateHeight from 'react-animate-height';
import Text from '../text';

const useStyles = makeStyles((theme) => ({
  readMoreCon: {
    width: '400px',
    padding: '50px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 20px',
      width: 'auto',
    },
  },
  text: {
    textAlign: 'center',
    overflow: 'hidden',
  },
  paragraph: {
    paddingBottom: '5px',

  },
  button: {},
}));

const ReadMore = ({ text, children }) => {
  const [showText, setShowText] = useState(false);
  const mobile = window.innerWidth < 500;

  const height = showText ? 'auto' : mobile ? 85 : 78;

  const classes = useStyles();
  const btnText = showText ? 'סגור' : 'קרא עוד';

  const toggleText = () => {
    setShowText(!showText);
  };

  return (
    <div className={classes.readMoreCon}>
      <AnimateHeight duration={500} height={height} className={classes.text}>
        <div className={classes.paragraph}>
          <Text size="sm">
            ארגון אורות שבראשו הרב אסף משה בילר, שם לו שאיפה קדושה וצנועה לעשות את חלקו בהבאת עם ישראל לגאולה ברחמים
          </Text>
        </div>
        <div className={classes.paragraph}>
          <Text size="sm">
            אנחנו כאן כדי להרוות את הצימאון וההשתוקקות של רבים מישראל בארץ ובעולם ללימוד רוחניות, חכמת הקבלה, פנימיות התורה, חסידות ושיפור עצמי באמצעות כלים נסתרים ועתיקים מחכמת היהדות המופלאה
          </Text>
        </div>
        <div className={classes.paragraph}>
          <Text size="sm">
          הרב אסף משה בילר הוא מרצה, מנחה סדנאות, ראש ישיבה לבעלי תשובה וראש הכולל בישוב אור הגנוז. נמנה מתלמידיו של הגאון המקובל הרב מרדכי שיינברגר שליט״א          </Text>
        </div>
      </AnimateHeight>
      <div className={classes.button}>
        <Button btnColor="blue" onClick={toggleText}>
          <Text color={'white'} size="sm">
            {btnText}
          </Text>
        </Button>
      </div>
    </div>
  );
};

export default ReadMore;
