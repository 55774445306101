import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HomeHeader from '../components/homePage/homeHeader';
import ReadMore from '../components/homePage/readMore';
import WeekLesson from '../components/homePage/weekLesson';
import Cards from '../components/cards';
import Carusel from '../components/homePage/carusel';
import { MainContext } from '../context';
import { getMapCategory } from '../utils/categoryFilter';
import Text from '../components/text';

const useStyles = makeStyles((theme) => ({
  homeCon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    margin: '30px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
  cardsCon: {
    maxWidth: '900px',
  },
}));

const Home = () => {
  const classes = useStyles();
  const { data } = useContext(MainContext);
  const categories = getMapCategory(data.categories, [0, 1]);
  return (
    <div className={classes.homeCon}>
      <HomeHeader />
      <ReadMore />
      <WeekLesson />
      <div className={classes.title}>
        <Text color="black" size="lg" fontWeight={600}>
          שיעורים וסדרות
        </Text>
      </div>
      <div className={classes.cardsCon}>
        <Cards categoryId="0" data={categories} />
      </div>
      <Carusel />
    </div>
  );
};

export default Home;
