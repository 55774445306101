import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import homeText from '../assests/texts/home';
import { Link } from 'react-router-dom';
import footerText from '../assests/texts/footer';
import Text from './text';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  topContainer: {
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/desFooter.webp)`,
    width: '100%',
    height: '400px',
    display: 'flex',
    backgroundSize: 'cover',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundPosition: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  linksContainer: {
    display: 'flex',
    direction: 'rtl',
    width: '100%',
    alignItems: 'center',
    padding: '0px 25px',
    marginTop: '120px',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    borderBottom: '2px solid #000',
    borderTop: '2px solid #000',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      borderTop: 'none',
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: '51px',
    },
  },
  pages: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      height: 'auto',
      width: '100%',
      padding: '10px 0px',
      boxSizing: 'border-box',
      marginBottom: '20px',
    },
  },
  allRightsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '50px',
    color: theme.palette.primary.gray,
    [theme.breakpoints.down('xs')]: {
      padding: '30px 0px',
      marginTop: '0px',
    },
  },
  link: {
    marginLeft: '35px',
    cursor: 'pointer',
    position: 'relative',
    direction: 'ltr',
    color: '#000',
    [theme.breakpoints.down('xs')]: {
      margin: '5px 10px',
    },
  },

  iconsContainer: {
    display: 'flex',
    padding: '20px',
    alignItems: 'center',
    width: '110px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: '-22px',
      left: 'calc(50% - 50px)',
      padding: '0px',
      width: '100px',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },

  wtLink: {
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/whatsupIcon.svg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '40px',
    marginRight: '20px',
    height: '40px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      width: '45px',
      height: '45px',
      marginRight: '0px',
    },
  },
  fbLink: {
    backgroundImage: `url(https://orot.sfo3.cdn.digitaloceanspaces.com/new-images/facebookIcon.svg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: '#fff',
    borderRadius: '50px',
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      width: '45px',
      height: '45px',
      backgroundColor: '#fff',
      borderRadius: '50px',
    },
  },
}));

const Footer = ({ text, onClick, btnColor }) => {
  const location = useLocation();

  const isActive = (linkTo) => {
    return linkTo === location.pathname || (linkTo !== '/' && location.pathname.includes(linkTo));
  };

  const classes = useStyles({ btnColor });
  return (
    <div className={classes.topContainer}>
      <div className={classes.linksContainer}>
        <div className={classes.pages}>
          <Link to={`/`} className={classes.link}>
            <Text size="md" color={isActive('/') ? 'yellow' : 'black'}
              textShadow={isActive('/') ? true : false} fontWeight={600}>דף הבית</Text>
          </Link>
          <Link to={`/series/0`} className={classes.link}>
            <Text size="md" color={isActive('/series') ? 'yellow' : 'black'}
              textShadow={isActive('/series') ? true : false} fontWeight={600}>כל השיעורים</Text>
          </Link>
          <Link to={`/partners`} className={classes.link}>
            <Text size="md" color={isActive('/partners') ? 'yellow' : 'black'}
              textShadow={isActive('/partners') ? true : false} fontWeight={600}>שותפות</Text>
          </Link>
          <Link to={`/about`} className={classes.link}>
            <Text size="md" color={isActive('/about') ? 'yellow' : 'black'}
              textShadow={isActive('/about') ? true : false} fontWeight={600} >אודות</Text>
          </Link>
        </div>

        <div className={classes.iconsContainer}>
          <Link className={classes.wtLink} to={{ pathname: footerText.whatsappLink }} target="_blank"></Link>
          <Link className={classes.fbLink} to={{ pathname: footerText.facebookLink }} target="_blank"></Link>
        </div>
      </div>
      <div className={classes.allRightsContainer}>
        <Text size="xs">{homeText.allRights}</Text>
      </div>
    </div>
  );
};

export default Footer;
